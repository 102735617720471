import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// initialize an empty api service that we'll inject endpoints into later as needed
const SERVER_API_URL = process.env.REACT_APP_API_URL
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_API_URL,
    prepareHeaders: (headers, { endpoint }) => {
      // Set the content-type only for specific endpoints. This is a workaround because the content type is not generated correctly
      if (endpoint === 'postOpenapiValidate') {
        headers.set('Content-Type', 'application/octet-stream');
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
})